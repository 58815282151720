import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import L, { LatLng } from 'leaflet';  // Import LatLng from leaflet for accurate typing
import 'leaflet/dist/leaflet.css';
import Papa from 'papaparse';
import {WeatherStation} from "./interfaces/interfaces";
import {parseDateString} from "../model/util";

interface MapSelectionProps {
  onLocationSelected?: (location: LatLng) => void; // Prop for handling location selection
  onStationSelected: (station:WeatherStation) => void; // Callback to update parent component
  onNext: () => void;
}

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

const MapSelection: React.FC<MapSelectionProps> = ({ onLocationSelected, onStationSelected }) => {
  const [position, setPosition] = useState<LatLng | null>(null);
  // make station type WeatherStation Array
  const [stations, setStations] = useState<WeatherStation[]>([]);

  useEffect(() => {
    // Fetch and parse CSV data
    const fetchStations = async () => {
      const response = await fetch('https://insightdev.kwiqly.com/weather/isd-history.csv.gz');
      const reader = response.body?.getReader();
      const result = await reader?.read(); // result.value is a Uint8Array
      const decoder = new TextDecoder('utf-8');
      const csv = decoder.decode(result?.value); // Convert the Uint8Array to string
      Papa.parse(csv, {
        header: true,
        transformHeader:function(h) {
          return h.replaceAll(" ", "_" )
        },
        complete: (results) => {
          setStations(results.data as WeatherStation[]);
        }
      });
    };

    fetchStations();
  }, []);

  const LocationMarker: React.FC = () => {
    const map = useMapEvents({
      click(e) {
        const newLatLng = e.latlng;
        setPosition(newLatLng);
        map.flyTo(newLatLng, map.getZoom());
        findClosestStation(newLatLng);
      }
    });

    return position === null ? null : <Marker position={position} />;
  };

  const findClosestStation = (location: LatLng) => {
    let minDistance = Infinity;
    let closestStation = null;
    //filter station that have recent data
    stations
      .filter(station=>parseDateString(station.END).getFullYear()===new Date().getFullYear())
      .forEach(station => {
      const distance = Math.sqrt(
        Math.pow(station.LAT - location.lat, 2) +
        Math.pow(station.LON - location.lng, 2)
      );

      if (distance < minDistance) {
        minDistance = distance;
        closestStation = station;
      }
    });

    if (closestStation) {

      // @ts-ignore
      onStationSelected(closestStation);
      onLocationSelected && onLocationSelected(location);
    }
  };

  return (
    <div>
      <MapContainer
        center={position || [51.505, -0.09]}
        zoom={5}
        style={{ height: "400px", width: "100%" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
        />
        <LocationMarker />
      </MapContainer>
    </div>
  );
};

export default MapSelection;
