import React from 'react';
import { scaleTime, scaleLinear } from '@visx/scale';
import { extent, max, bisector } from 'd3-array';
import { AreaClosed } from '@visx/shape';
import {
  AxisBottom,
  AxisLeft,
} from '@visx/axis';
import { GridRows, GridColumns } from '@visx/grid';
import { Group } from '@visx/group';
import { curveMonotoneX } from '@visx/curve';
import { timeFormat, utcFormat } from 'd3-time-format';
import { PentileDataPoint, PentileDataPointCompact } from '../../domain/PentileDataPoint';
import { localPoint } from '@visx/event';

interface NtileEventProfileChartProps {
  pentiledModel: PentileDataPointCompact[];
  pentiledEventModel: PentileDataPoint[];
  title?: string;
  width: number;
  height: number;
}

const NtileEventProfileChart: React.FC<NtileEventProfileChartProps> = ({
  pentiledModel,
  pentiledEventModel,
  title,
  width,
  height,
}) => {
  const [visiblePentiles, setVisiblePentiles] = React.useState<{[key: number]: boolean}>({
    1: true,
    2: true, 
    3: true,
    4: true,
    5: true
  });

  const [visiblePentilesEvent, setVisiblePentilesEvent] = React.useState<{[key: number]: boolean}>({
    1: true,
    2: true, 
    3: true,
    4: true,
    5: true
  });
  const togglePentile = (pentile: number) => {
    setVisiblePentiles(prev => ({
      ...prev,
      [pentile]: !prev[pentile]
    }));
  };

  const togglePentileEvent = (pentile: number) => {
    setVisiblePentilesEvent(prev => ({
      ...prev,
      [pentile]: !prev[pentile]
    }));
  };

  const margin = { top: 50, right: 50, bottom: 100, left: 80 };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

   
  const processedPentiledModel = pentiledModel.map((d) => ({
    ...d,
    date: d.time_of_week,
  }));

  const processedPentiledEventModel = pentiledEventModel.map((d) => ({
    ...d,
    date: d.time_of_week,
  }));

  const allDates = [
    ...processedPentiledModel.map((d) => d.date),
    ...processedPentiledEventModel.map((d) => d.date),
  ];

  const xDomain = extent(allDates) as [Date, Date];

  const allReadings = [
    ...processedPentiledModel.map((d) => max(d.reading)),
    ...processedPentiledEventModel.map((d) => d.reading),
  ].filter((value): value is number => value !== undefined);

  const yMaxValue = max(allReadings) || 0;

  const xScale = scaleTime({
    domain: xDomain,
    range: [0, innerWidth],
  });

  const yScale = scaleLinear({
    domain: [0, yMaxValue],
    range: [innerHeight, 0],
    nice: true,
  });

  const pentileColors = ['#0000ff', 'lightblue', '#2ca02c', 'orange', '#ff0000'];
  const pentileNames = ['Very Cool', 'Cool', 'Mild', 'Warm', 'Very Hot'];

  const priorYearAreas = [];
  const eventMarkers = [];
  const openingHours = [];
  for (let i = 1; i <= 5; i++) {
    const pentile = i;
    const pcol = pentileColors[i - 1];
    const pname = pentileNames[i - 1];
    const isVisible = visiblePentiles[pentile];
    const isEventVisible = visiblePentilesEvent[pentile];

    if (isVisible) {
      const pentileData = processedPentiledModel
        .sort((a, b) => a.date.getTime() - b.date.getTime());
     
      priorYearAreas.push(
        <AreaClosed<any>
          key={`prior-year-area-${pentile}`}
          data={pentileData.map((d: any) => ({
            date: d.date,
            reading: d.reading[pentile - 1]
          }))}
          x={(d) => xScale(d.date) ?? 0}
          y={(d) => yScale(d.reading) ?? 0}
          yScale={yScale}
          fill={pcol}
          fillOpacity={0.7}
          strokeWidth={0}
          curve={curveMonotoneX} />
         
      );
      openingHours.push(
        pentileData.map((day, dayIdx) => day.mode?.map((mode, pentileIdx) => (

          <rect
        
          key={`opening-hours-${pentile}-rect-${dayIdx}-${pentileIdx}`}
          x={xScale(day.date) ?? 0}
          y={yScale(0) ?? 0}
          width={2}
          height={10}
          fill={visiblePentiles[pentileIdx+1]&&mode === 1 ? getColorByMode(pentileIdx+1) : 'transparent'}
          opacity={0.7}
          fillOpacity={0.9} /> 
        ))
        )
    );
  }
  if( visiblePentilesEvent[pentile]){
    const eventData = processedPentiledEventModel
    .filter((d) => d.pentile === pentile)
    .sort((a, b) => a.date.getTime() - b.date.getTime());
  
      eventMarkers.push(
        eventData.map((d, idx) => (
          <circle
            key={`event-marker-${pentile}-${idx}`}
            cx={xScale(d.date)}
            cy={yScale(d.reading)}
            r={1}
            fill={pcol}
            stroke={pcol}
          />
        )))

       
    }
  }

  const formatDate = timeFormat('%A %H:%M');

  const legendEntries = [];

  for (let i = 1; i <= 5; i++) {
    const pname = pentileNames[i - 1];
    const pcol = pentileColors[i - 1];
    const isVisible = visiblePentiles[i];
    const isEventVisible = visiblePentilesEvent[i];

    legendEntries.push(
      <div
        key={`legend-entry-${i}`}
        style={{ 
          display: 'flex', 
          alignItems: 'center', 
          marginRight: '15px',
          cursor: 'pointer',
          opacity: isVisible ? 1 : 0.5 
        }}
        onClick={() => togglePentile(i)}
      >
        <svg width={10} height={10}>
          <rect width={10} height={10} fill={pcol} fillOpacity={0.7} />
        </svg>
        <span style={{ marginLeft: '5px', fontSize: '12px' }}>{`Year Prior ${pname}`}</span>
      </div>
    );

    legendEntries.push(
      <div
        key={`legend-entry-event-${i}`}
        style={{ 
          display: 'flex', 
          alignItems: 'center', 
          marginRight: '15px',
          cursor: 'pointer',
          opacity: isEventVisible ? 1 : 0.5
        }}
        onClick={() => togglePentileEvent(i)}
      >
        <svg width={10} height={10}>
          <circle cx={5} cy={5} r={5} fill={pcol} />
        </svg>
        <span style={{ marginLeft: '5px', fontSize: '12px' }}>{`Event ${pname}`}</span>
      </div>
    );
  }

  const [tooltipData, setTooltipData] = React.useState<{x: number, y: number, data: any} | null>(null);

  const Tooltip = ({ x, y, data }: { x: number; y: number; data: any }) => (
    <div
      style={{
        position: 'absolute',
        left: `${x}px`,
        top: `${y}px`,
        backgroundColor: 'white',
        opacity: 0.7,
        padding: '5px',
        border: '0px solid #999',
        borderRadius: '3px',
        pointerEvents: 'none',
      }}
    > 
      <table>
        
        <tr style={{opacity: 0.9, color: 'darkblue'}}><th colSpan={2}>{(() => {
          return timeFormat('%A %H:%M')(data.date);
        })()}</th></tr>
        {data.reading.map((value: number, idx: number) => (
          <tr key={idx} style={{opacity: 0.7}}>
            <th style={{textAlign: 'left'}}>{pentileNames[idx]}</th>
            <td style={{textAlign: 'right'}}>{value.toFixed(2)} kW</td>
          </tr>
        ))}
      </table>
    </div>
  );

  const handleMouseMove = (event: React.MouseEvent<SVGGElement>) => {
    const { x } = localPoint(event) || { x: 0 };
  
    const mouseDate =  xScale.invert(x - margin.left);
    const mouseTime = mouseDate.getTime();
    const nearestPoint = processedPentiledModel.reduce((prev, curr) => {
      const prevDiff = Math.abs(prev.date.getTime() - mouseTime);
      const currDiff = Math.abs(curr.date.getTime() - mouseTime);
      return prevDiff < currDiff ? prev : curr;
    });
    const { y } = localPoint(event) || { y: 0 };
    setTooltipData({
      x: xScale(nearestPoint.date),
      y: y,
      data: nearestPoint
    });
  };

  const handleMouseLeave = () => {
    setTooltipData(null);
  };

  function getColorByMode(mode: number | undefined): string | undefined {
    if (mode === undefined) {
      return undefined;
    }
    return pentileColors[mode - 1];
  }

  return (
    <div style={{ position: 'relative' }}>
      {title && <h3 style={{ textAlign: 'center' }}>{title}</h3>}
      <svg 
        width={width} 
        height={height}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <Group left={margin.left} top={margin.top}>
          <GridRows scale={yScale} width={innerWidth} stroke="#ddd" strokeWidth={1} />
          <GridColumns scale={xScale} height={innerHeight} stroke="#ddd" strokeWidth={1} />
          {openingHours}
          {priorYearAreas}
          {eventMarkers}
         
          <AxisBottom
            top={innerHeight}
            scale={xScale}
          
            tickValues={xScale.ticks().filter((_, i) => i % 2 === 0)}
            tickFormat={(date) => formatDate(date as Date)}
            tickLabelProps={() => ({
              fontSize: 8,
              textAnchor: 'middle',
              
              
            })}
            stroke="#000"
            tickStroke="#000"
          />
          <AxisLeft
            scale={yScale}
            tickFormat={(d) => d.toString()}
            tickLabelProps={() => ({
              fontSize: 10,
              textAnchor: 'end',
              dy: '0.33em',
            })}
            label="Power (kW)"
           
            stroke="#000"
            tickStroke="#000"
          />
        </Group>
      </svg>
      {tooltipData && (
        <Tooltip 
          x={tooltipData.x} 
          y={tooltipData.y} 
          data={tooltipData.data}
        />
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          marginTop: '10px',
        }}
      >
        {legendEntries}
      </div>
    </div>
  );
};

export default NtileEventProfileChart;
