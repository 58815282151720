import React, { useMemo, useState, useRef, useCallback, useEffect } from 'react';
import { scaleTime, scaleLinear, scaleOrdinal } from '@visx/scale';
import { Group } from '@visx/group';
import { AxisLeft, AxisBottom } from '@visx/axis';
import { LinePath, Line } from '@visx/shape';
import { extent, bisector } from 'd3-array';

import { useTooltip } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import Legend from '../Legend';
import { GridRows, GridColumns } from '@visx/grid';
import { RawDataItem } from '../../domain/RawDataItem';
import { ModeledData} from '../../domain/ModeledData';
import DownloadChartButton from '../DownloadChartButton';

type ProcessedModeledData = ModeledData & { date: Date };

const colors = ["#1B9E77", "#D95F02", "#7570B3", "#E7298A", "#66A61E", "#E6AB02", "#A6761D"];
const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

interface TimeChartProps {
  data: ModeledData[];
  cleanedReadings: RawDataItem[];
  excludedReadings: RawDataItem[];
  width: number;
  height: number;
  xDomain: [Date, Date];
}

const TimeChart: React.FC<TimeChartProps> = ({ data, cleanedReadings, excludedReadings, width, height, xDomain }) => {
  // Helper function to ensure consistent date handling
  const ensureDate = (date: Date | string): Date => {
    return date instanceof Date ? date : new Date(date);
  };

  const processDataPoints = <T extends { ts: string | Date }>(items: T[]): (T & { date: Date })[] => {
    return items.map(item => ({
      ...item,
      date: ensureDate(item.ts)
    }));
  };

  const margin = { top: 20, right: 20, bottom: 60, left: 40 };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const [xDomainState, setXDomainState] = useState<[Date, Date]>(xDomain);
  const [hoveredPoint, setHoveredPoint] = useState<ProcessedModeledData | null>(null);
  const [tooltipPos, setTooltipPos] = useState({ x: 0, y: 0 });
  const [hiddenWeekdays, setHiddenWeekdays] = useState<number[]>([]);
  const [showExcluded, setShowExcluded] = useState(false);

  const {
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  } = useTooltip<ModeledData>();

  // Process data once with proper date objects
  const processedData = useMemo(() => processDataPoints(data), [data]);
  const processedCleanedReadings = useMemo(() => processDataPoints(cleanedReadings), [cleanedReadings]);
  const processedExcludedReadings = useMemo(() => processDataPoints(excludedReadings), [excludedReadings]);

  // Update xDomainState when xDomain prop changes
  useEffect(() => {
    setXDomainState(xDomain);
  }, [xDomain]);

  // Combine scale calculations
  const { xScale, yScale } = useMemo(() => {
    const x = scaleTime({
      range: [0, innerWidth],
      domain: xDomainState,
    });

    const [min, max] = extent(processedCleanedReadings, d => d.rate) as [number, number];
    const y = scaleLinear({
      range: [innerHeight, 0],
      domain: [Math.floor(min), Math.ceil(max)],
      nice: true,
    });

    return { xScale: x, yScale: y };
  }, [innerWidth, innerHeight, xDomainState, processedCleanedReadings]);

  // Memoize axis ticks and gridlines together
  const { yTicks, xTicks } = useMemo(() => ({
    yTicks: yScale.ticks().filter(Number.isInteger),
    xTicks: xScale.ticks(12)
  }), [yScale, xScale]);

  // Group data by weekday once
  const groupedData = useMemo(() => {
    const groups: { [key: number]: ProcessedModeledData[] } = {};
    for (let i = 1; i <= 7; i++) {
      groups[i] = processedData.filter(d => d.iso_dow === i);
    }
    return groups;
  }, [processedData]);

  const bisectDate = useMemo(() => bisector<ProcessedModeledData, Date>(d => d.date).left, []);

  const getClosestPoint = (d0: ProcessedModeledData, d1: ProcessedModeledData, xDate: Date, hiddenWeekdays: number[]) => {
    if (hiddenWeekdays.includes(d0.iso_dow)) {
      return d1;
    } else if (hiddenWeekdays.includes(d1.iso_dow)) {
      return d0;
    } else {
      // Both points visible, choose the closest one
      return xDate.valueOf() - d0.date.valueOf() > d1.date.valueOf() - xDate.valueOf() ? d1 : d0;
    }
  };

  const handleMouseMove = useCallback(
    (event: React.MouseEvent<SVGElement>) => {
      const { x, y } = localPoint(event) || { x: 0, y: 0 };
      const xDate = xScale.invert(x - margin.left);
      const index = bisectDate(processedData, xDate, 1);
      
      // Get the two closest points
      const d0 = processedData[index - 1];
      const d1 = processedData[index];
      
      if (!d0 || !d1 || (hiddenWeekdays.includes(d0.iso_dow) && hiddenWeekdays.includes(d1.iso_dow))) {
        return;
      }
      
      const closestPoint = getClosestPoint(d0, d1, xDate, hiddenWeekdays);
      if (!closestPoint) return;
      
      const tooltipX = x >= width / 2 ? x - 10 : x + 60;
      setHoveredPoint(closestPoint);
      setTooltipPos({ x: tooltipX, y });
      showTooltip({
        tooltipData: closestPoint,
        tooltipLeft: x,
        tooltipTop: y,
      });
    },
    [showTooltip, xScale, processedData, width, margin.left, bisectDate, hiddenWeekdays]
  );

  const handleMouseLeave = useCallback(() => {
    hideTooltip();
    setHoveredPoint(null);
    setTooltipPos({ x: 0, y: 0 });
  }, [hideTooltip]);

  const toggleExcluded = useCallback(() => {
    setShowExcluded(prev => !prev);
    setXDomainState(xDomain); // Reset to initial domain when toggling excluded data
  }, [xDomain]);

  const toggleDay = useCallback((isoDow: number) => {
    setHiddenWeekdays(prev => {
      const newHiddenDays = prev.includes(isoDow)
        ? prev.filter(d => d !== isoDow)
        : [...prev, isoDow];
      return newHiddenDays;
    });
    setXDomainState(xDomain); // Reset to initial domain when toggling days
  }, [xDomain]);

  const chartRef = useRef<SVGSVGElement>(null);

  // Zoom functionality
  const [zoomRect, setZoomRect] = useState<{ startX: number; endX: number } | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseDown = useCallback((event: React.MouseEvent<SVGElement>) => {
    const point = localPoint(event);
    if (point) {
      setZoomRect({ startX: point.x - margin.left, endX: point.x - margin.left });
      setIsDragging(true);
    }
  }, [margin.left]);

  const handleDragMove = useCallback((event: React.MouseEvent<SVGElement>) => {
    if (!isDragging) return;
    const point = localPoint(event);
    if (point) {
      setZoomRect(prev => prev ? { ...prev, endX: point.x - margin.left } : null);
    }
  }, [isDragging, margin.left]);

  const handleDragEnd = useCallback(() => {
    setIsDragging(false);
    if (zoomRect) {
      const { startX, endX } = zoomRect;
      const visibleData = [...processedCleanedReadings, ...(showExcluded ? processedExcludedReadings : [])]
        .filter(d => !hiddenWeekdays.includes(d.iso_dow));
      
      const timeMin = Math.min(...visibleData.map(d => d.date.getTime()));
      const timeMax = Math.max(...visibleData.map(d => d.date.getTime()));
      
      let x1 = xScale.invert(Math.min(startX, endX));
      let x2 = xScale.invert(Math.max(startX, endX));
      
      // Constrain zoom to visible data range
      x1 = new Date(Math.max(x1.getTime(), timeMin));
      x2 = new Date(Math.min(x2.getTime(), timeMax));
      
      setXDomainState([x1, x2]);
    }
    setZoomRect(null);
  }, [xScale, zoomRect, processedCleanedReadings, processedExcludedReadings, hiddenWeekdays, showExcluded]);

  const resetZoom = useCallback(() => {
    setXDomainState(xDomain);
  }, [xDomain]);

  const colorScale = useMemo(
    () => scaleOrdinal({
      domain: weekdays,
      range: colors,
    }),
    []
  );

  return (
    <div style={{ position: 'relative' }}>
      <DownloadChartButton chartRef={chartRef} filename="time-chart" />
      <button onClick={() => setXDomainState(xDomain)} style={{ position: 'absolute', top: -10, right: 10 }}>
        Reset Zoom
      </button>
      <svg ref={chartRef} width={width} height={height}
           onMouseMove={handleMouseMove}
           onMouseLeave={handleMouseLeave}
           onMouseDown={handleMouseDown}
           onMouseMoveCapture={handleDragMove}
           onMouseUp={handleDragEnd}
          
      >
        <defs>
          <symbol id="andreas-cross">
            <line 
              x1="0" y1="0"
              x2="8" y2="8"
              strokeWidth={1.5}
            />
            <line
              x1="0" y1="8"
              x2="8" y2="0"
              strokeWidth={1.5}
            />
          </symbol>
        </defs>
        <Group left={margin.left} top={margin.top}>
          <GridRows
            scale={yScale}
            width={innerWidth}
            height={innerHeight}
            stroke="#e0e0e0"
            strokeOpacity={0.5}
            tickValues={yTicks}
          />
          <GridColumns
            scale={xScale}
            width={innerWidth}
            height={innerHeight}
            stroke="#e0e0e0"
            strokeOpacity={0.5}
            tickValues={xTicks}
          />
          {processedCleanedReadings
            .filter(d => !hiddenWeekdays.includes(d.iso_dow))
            .filter(d => d.cause === 'Clean' && d.date && d.rate && !isNaN(d.rate) && isFinite(d.rate))
            .map((d, i) => {
              const xPos = xScale(d.date);
              const yPos = yScale(d.rate);
              
              // Skip rendering if any coordinate is invalid
              if (isNaN(xPos) || isNaN(yPos) || !isFinite(xPos) || !isFinite(yPos)) {
                return null;
              }
              
              return (
                <circle
                  key={i}
                  cx={xPos}
                  cy={yPos}
                  r={3}
                  fill={colorScale(weekdays[d.iso_dow - 1])}
                  stroke={colorScale(weekdays[d.iso_dow - 1])}
                />
              );
            })}

          {Object.entries(groupedData).map(([iso_dow, rows]) => {
            if (hiddenWeekdays.includes(Number(iso_dow))) return null;
            return (
              <LinePath
                key={iso_dow}
                data={rows}
                x={d => xScale(d.date)}
                y={d => yScale(d.pred)}
                stroke={colorScale(weekdays[Number(iso_dow) - 1])}
                strokeWidth={0.7}
              />
            );
          })}
          {showExcluded && processedExcludedReadings.filter(d => !hiddenWeekdays.includes(d.iso_dow))
            .map((d, i) => (
              <use
                key={i}
                href="#andreas-cross"
                x={xScale(d.date)-4}
                y={yScale(d.rate||0)-4}
                stroke={colorScale(weekdays[d.iso_dow - 1])}
              />
            ))}
         
          <AxisBottom 
            top={innerHeight} 
            scale={xScale} 
            tickValues={xTicks}
          />
          <AxisLeft
            scale={yScale}
            numTicks={6}
            tickFormat={(value) => `${Math.round(+value)}` }
            tickValues={yTicks}
          />
          
          {tooltipData && tooltipLeft !== undefined && (
            <Line
              from={{ x: tooltipLeft - margin.left, y: 0 }}
              to={{ x: tooltipLeft - margin.left, y: innerHeight }}
              stroke="#999"
              strokeWidth={1}
              pointerEvents="none"
            />
          )}

          {/* Zoom rectangle */}
          {zoomRect && (
            <rect
              x={Math.min(zoomRect.startX, zoomRect.endX)}
              y={margin.top}
              width={Math.abs(zoomRect.endX - zoomRect.startX)}
              height={innerHeight}
              fill="rgba(0, 128, 255, 0.2)"
              stroke="blue"
              strokeWidth={1}
              strokeDasharray="4 4"
            />
          )}
        </Group>
        
        <text
          x={-height / 2}
          y={15}
          transform="rotate(-90)"
          textAnchor="middle"
          fontSize={12}
          fill="#000000"
        >
          Power (kW)
        </text>

        <g transform={`translate(${margin.left}, ${height - margin.bottom + 40})`}>
          <Legend
            days={weekdays}
            colors={colors}
            visibleDays={new Set(weekdays.map((_, i) => i + 1).filter(day => !hiddenWeekdays.includes(day)))}
            toggleDay={toggleDay}
            width={innerWidth}
            showExcluded={showExcluded}
            toggleExcluded={toggleExcluded}
          />
        </g>
      </svg>
      
      {tooltipData && (
        <div
          style={{
            position: 'absolute',
            top: tooltipPos.y,
            left: tooltipPos.x,
            transform: `translate(${tooltipPos.x > width / 2 ? '-100%' : '0%'}, -50%)`,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            padding: '8px',
            borderRadius: '4px',
            boxShadow: '0 1px 10px rgba(0,0,0,0.1)',
            pointerEvents: 'none',
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            gap: '4px 8px',
          }}
        >
          <div style={{ textAlign: 'left' }}><strong>Date</strong></div>
          <div style={{ textAlign: 'right' }}>{new Date(hoveredPoint?.date || '').toLocaleDateString()}</div>
          <div style={{ textAlign: 'left' }}><strong>Weekday</strong></div>
          <div style={{ textAlign: 'right' }}>{weekdays[(hoveredPoint?.iso_dow || 1) - 1]}</div>
          <div style={{ textAlign: 'left' }}><strong>Rate</strong></div>
          <div style={{ textAlign: 'right' }}>{hoveredPoint?.rate?.toFixed(2)}</div>
          <div style={{ textAlign: 'left' }}><strong>Expected</strong></div>
          <div style={{ textAlign: 'right' }}>{hoveredPoint?.pred.toFixed(2)}</div>
        </div>
      )}
    </div>
  );
};

export default TimeChart;