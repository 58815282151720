import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './ModernLandingPage.css';

const ModernLandingPage: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in-visible');
            
            // Add fade-in-visible to child elements that need animation
            entry.target.querySelectorAll('.tech-item, .stat').forEach(el => {
              el.classList.add('fade-in-visible');
            });
          }
        });
      },
      { threshold: 0.1, rootMargin: '50px' }
    );

    // Observe all fade-in elements
    document.querySelectorAll('.fade-in').forEach((el) => {
      observerRef.current?.observe(el);
    });

    // Add parallax effect to hero visual
    const handleScroll = () => {
      const heroVisual = document.querySelector('.hero-visual') as HTMLElement;
      if (heroVisual) {
        const scrolled = window.scrollY;
        const rate = scrolled * 0.15;
        heroVisual.style.transform = `translate3d(0, ${rate}px, 0)`;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      observerRef.current?.disconnect();
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="modern-landing">
      <nav className="modern-nav">
        <div><img className="navbar-brand" src="/logo_small.svg"/><span className="navbar-version">1.0.1</span></div>
        <div className="nav-links">
          <Link to="/waitinglist" className="nav-link">Join Waitlist</Link>
          <Link to="/login" className="nav-button">Sign In</Link>
        </div>
      </nav> 

      <section className="hero-section">
        <div className="hero-content">
          <h1 className="fade-in" data-animation="left">Building Energy Intelligence</h1>
          <p className="fade-in" data-animation="left">
            Using advanced AI to optimize energy consumption and reduce environmental impact
          </p>
          <div className="hero-cta fade-in" data-animation="zoom">
            <Link to="/waitinglist" className="cta-button">
              Join the Future
              <span className="arrow">→</span>
            </Link>
          </div>
        </div>
        <div className="hero-visual fade-in" data-animation="right">
          <div className="visual-container">
            <div className="data-visualization">
              {/* Animated data visualization effect */}
              <div className="data-point"></div>
              <div className="data-point"></div>
              <div className="data-point"></div>
              <div className="data-line"></div>
              <div className="data-line"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="features-section">
        <div className="feature fade-in" data-animation="zoom">
          <div className="feature-icon">
            <div className="icon-circle">
              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M12 2v20M2 12h20M12 12h.01" />
              </svg>
            </div>
          </div>
          <h3>Precision Monitoring</h3>
          <p>Real-time energy consumption tracking with millisecond accuracy</p>
        </div>

        <div className="feature fade-in" data-animation="zoom">
          <div className="feature-icon">
            <div className="icon-circle">
              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                <path d="M9 12l2 2 4-4" />
              </svg>
            </div>
          </div>
          <h3>Predictive Analytics</h3>
          <p>AI-powered forecasting to optimize energy usage patterns</p>
        </div>

        <div className="feature fade-in" data-animation="zoom">
          <div className="feature-icon">
            <div className="icon-circle">
              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M13 2L3 14h9l-1 8 10-12h-9l1-8z" />
              </svg>
            </div>
          </div>
          <h3>Automated Optimization</h3>
          <p>Smart algorithms that automatically adjust for maximum efficiency</p>
        </div>
      </section>

      <section className="stats-section fade-in" data-animation="left">
        <div className="stat">
          <h4>30%</h4>
          <p>Average Energy Savings</p>
        </div>
        <div className="stat">
          <h4>24/7</h4>
          <p>Continuous Monitoring</p>
        </div>
        <div className="stat">
          <h4>15min</h4>
          <p>Setup Time</p>
        </div>
      </section>

      <section className="tech-section">
        <div className="tech-content fade-in" data-animation="right">
          <h2>Advanced Technology</h2>
          <p>
            Our AI models process millions of data points per second to identify patterns
            and optimize energy consumption in real-time.
          </p>
          <div className="tech-grid">
            <div className="tech-item">
              <div className="tech-icon">AI</div>
              <span>Machine Learning</span>
            </div>
            <div className="tech-item">
              <div className="tech-icon">IoT</div>
              <span>Smart Sensors</span>
            </div>
            <div className="tech-item">
              <div className="tech-icon">API</div>
              <span>Open Platform</span>
            </div>
          </div>
        </div>
      </section>

      <footer className="modern-footer">
        <div className="footer-content">
          <p> 2024 kWIQly GmbH</p>
          <div className="footer-links">
            <a href="/privacy">Privacy</a>
            <a href="/terms">Terms</a>
            <a href="/contact">Contact</a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ModernLandingPage;
