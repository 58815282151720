import { ModeledData } from "../../domain/ModeledData";
import { ModeledDataItem } from "../../domain/ModeledDataItem";
import { NumberedModelRow } from "../../domain/NumberedModelRow";


interface ValidDayData {
    ts: Date;
    corrected_residuals: number;
    rc?: number;
    event_no?: number;
}

/**
 * Find peaks in a numeric array using a rolling window
 * @param data Array of numbers to find peaks in
 * @param span Window size for peak detection
 * @param strict If true, only strict peaks are detected
 * @param endBehavior How to handle window ends (0 = no events in half window)
 */
function findPeaks(data: number[], span: number, strict: boolean = false, endBehavior: number = 0): boolean[] {
    const result = new Array(data.length).fill(false);
    const halfSpan = Math.floor(span / 2);

    // Skip the edges according to endBehavior
    for (let i = halfSpan; i < data.length - halfSpan; i++) {
        let isPeak = true;
        for (let j = i - halfSpan; j <= i + halfSpan; j++) {
            if (j === i) continue;
            if (strict ? data[j] >= data[i] : data[j] > data[i]) {
                isPeak = false;
                break;
            }
        }
        result[i] = isPeak;
    }

    return result;
}

/**
 * Define history of events from model
 * @param model The filtered fit augmented filtered data or raw_data model
 * @returns Model annotated with event number or null
 */
export function numberEvents(model: ModeledData[]): NumberedModelRow[] {
    // Sort data by timestamp
    
    const sortedModel = [...model].sort((a, b) => new Date(a.ts).getTime() - new Date(b.ts).getTime());
 
    // Extract valid day data (omitting NA values)
    const validDayData: ValidDayData[] = sortedModel
        .filter(row => row.waste !== null && !isNaN(row.waste))
        .map(row => ({
            ts: new Date(row.ts),
            corrected_residuals: row.waste
        }));

    // Calculate cumulative sum of corrected residuals
    let sum = 0;
    validDayData.forEach(row => {
        sum += row.corrected_residuals;
        row.rc = sum;
    });

    // Define rolling window for peaks and troughs
    const span = 15; // (two weeks - 1) Days before/after middle of rolling window
    const rcValues = validDayData.map(row => row.rc!);
    
    // Find peaks and troughs
    const localPeaks = findPeaks(rcValues, span, false, 0);
    const localTroughs = findPeaks(rcValues.map(x => -x), span, false, 0);

    // Remove consecutive peaks/troughs (equivalent to R's head operation)
    for (let i = 1; i < localPeaks.length; i++) {
        if (localPeaks[i] && localPeaks[i - 1]) localPeaks[i] = false;
        if (localTroughs[i] && localTroughs[i - 1]) localTroughs[i] = false;
    }

    // Calculate change days and event numbers
    const changeDays = localPeaks.map((peak, i) => peak ? 1 : (localTroughs[i] ? -1 : 0));
    let eventNo = 1;
    validDayData.forEach((row, i) => {
        if (changeDays[i] !== 0) eventNo++;
        row.event_no = eventNo;
    });

    // Join event numbers back to original model
    const numberedModel = sortedModel.map(row => {
        const validDay = validDayData.find(vd => new Date(vd.ts).getTime() === new Date(row.ts).getTime());
        return {
            ...row,
            event_no: validDay?.event_no ?? null
        };
    });
   
    // Set first day event_no to 0
    if (numberedModel.length > 0) {
        numberedModel[0].event_no = 0;
    }

    // Fill NA event_no values with last known event_no (equivalent to cummax in R)
    let lastEventNo = 0;
    numberedModel.forEach(row => {
        if (row.event_no === null) {
            row.event_no = lastEventNo;
        } else {
            lastEventNo = row.event_no;
        }
    });

 
    //@ts-ignore
    return numberedModel;
}
