import React, { useRef, useEffect, useState } from 'react';
import { useAuth } from "react-oidc-context";
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Menu } from "primereact/menu";
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';

import { NumberedModelRow } from '../domain/NumberedModelRow';

import TimeChart from './charts/TimeChart';
import OATChart from './charts/OATChart';
import EventHistoryChart from './charts/EventHistoryChart';
import ProfileChart from './charts/ProfileChart';
import WasteExpectedProfileChart from './charts/WasteExpectedChart';
import { annual_model, DayFeatures, getDayFeatures } from '../model/kfeature';

import { KPIBox, KPIData } from './KPIBox'; // New component
import './AppContent.css'; // Add this import for the new CSS file

const AppContent: React.FC = () => {
  const auth = useAuth();
  const menuLeft = useRef<Menu>(null);
  const { meterkey } = useParams<{ meterkey: string }>();
  const location = useLocation();

  const [data2, setData2] = useState(null);
  const [data3, setData3] = useState<NumberedModelRow[] | null>(null);
  const [profileData, setProfileData] = useState<ProfileData[] | null>(null);
  const [dayFeatures, setDayFeatures] = useState<DayFeatures>()

  const [error, setError] = useState<string | null>(null);

  const [chartDimensions, setChartDimensions] = useState({ width: 1000, height: 400 });

  useEffect(() => {
    const updateDimensions = () => {
      const width = window.innerWidth * 0.9; // 90% of window width
      const height = window.innerHeight * 0.4; // 40% of window height
      setChartDimensions({ width, height });
    };

    updateDimensions(); // Set initial dimensions
    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!meterkey) {
        console.error('Meterkey is undefined. Current path:', location.pathname);
        setError('Meterkey is undefined');
        return;
      }

      const fetchEventModel = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/numbered_event_model`, {
            params: { meter_key: meterkey }
          });
          setData2(response.data);
          if (response.data) {
            
          }
        } catch (error) {
          console.error('Error fetching event model:', error);
          setError('Failed to fetch event model. Please try again later.');
        }
      };

      const fetchProfile = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/typical_profile`, {
            params: { meter_key: meterkey }
          });
          setProfileData(response.data);
        } catch (error) {
          console.error('Error fetching profile data:', error);
          setError('Failed to fetch profile data. Please try again later.');
        }
      };

      fetchEventModel();
      fetchProfile();
    };

    fetchData();
  }, [meterkey, location.pathname]);

  const kpiData: KPIData[] = [
    { title: "Day Distribution", value: dayFeatures?.dayDistribution, color: "orange" },
    { title: "Compensation", value: dayFeatures?.compensation, color: "green" },
    { title: "Seasonal Base Load", value: dayFeatures?.seasonalBaseLoad, color: "green" },
    { title: "Annual Load", value: dayFeatures?.annualLoad, color: "green" },
    { title: "Seasonal Balance", value: dayFeatures?.seasonalBalance, color: "red", unit: "°C", decimals: 1 },
    { title: "Convexity Excess", value: dayFeatures?.convexityExcess, color: "green" },
    { title: "Asymmetry Rising", value: dayFeatures?.asymmetryRising, color: "green" },
    { title: "Asymmetry Falling", value: dayFeatures?.asymmetryFalling, color: "orange" },
    { title: "Asymmetry Waste", value: dayFeatures?.asymmetryWaste, color: "green" },
  ];

  return (
    <React.Fragment>
      {error ? (
        <div className="error-container">
          <Message severity="error" text={error} />
        </div>
      ) : data2 ? (
        <React.Fragment>
          <div className="kpi-container">
            {kpiData.map((kpi, index) => (
              <div className="kpi-box-wrapper" key={index}>
                <KPIBox {...kpi} />
              </div>
            ))}
          </div>

          <div className="diagramm-container">
            <div className="diagramm card">
                <h4>Time Chart</h4>
              <TimeChart xDomain={[new Date(), new Date()]} excludedReadings={[]} cleanedReadings={[]} data={data2} width={chartDimensions.width} height={chartDimensions.height} />
            </div>
            <div className="diagramm card">
            <h4>OAT Chart</h4>
              <OATChart rawData={[]} data={data2} width={chartDimensions.width} height={chartDimensions.height} />
            </div>
            
            <div className="diagramm card">
            <h4>Profile Chart</h4>
              {profileData ? (
                <ProfileChart data={profileData} width={chartDimensions.width} height={chartDimensions.height}/>
              ) : (
                <div className="flex justify-content-center">
                  <ProgressSpinner />
                </div>
              )}
            </div>
            <div className="diagramm card">
                <h4>Waste vs Expected by Minute of Week</h4>
              {profileData ? (
                <WasteExpectedProfileChart typical_profile={profileData ?? []} width={chartDimensions.width} height={chartDimensions.height} />
              ) : (
                <div className="flex justify-content-center">
                  <ProgressSpinner />
                </div>
              )}
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div className="flex justify-content-center">
          <ProgressSpinner />
        </div>
      )}
    </React.Fragment>
  );
};

export default AppContent;