import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css';

const LandingPage: React.FC = () => {
  return (
    <div className="landing-container">
      <nav className="landing-nav">
      <div><img className="navbar-brand" src="/logo_small.svg"/><span className={'title'}></span> <span className="navbar-version">1.0.1</span></div>
  
        <div className="nav-links">
          <Link to="/waitinglist" className="nav-link">Join Waitlist</Link>
          <Link to="/login" className="nav-button">Sign In</Link>
        </div>
      </nav>

      <main className="hero-section">
        <h1>Smart Energy Management Made Simple</h1>
        <p>Monitor, analyze, and optimize your energy consumption with real-time insights</p>
        
        <div className="features-grid">
          <div className="feature-card">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M12 2v20M2 12h20" />
              <circle cx="12" cy="12" r="10" />
            </svg>
            <h3>Monitoring</h3>
            <p>Track energy usage across all your meters</p>
          </div>

          <div className="feature-card">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3" />
              <path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5" />
              <path d="M21 5c0 1.66-4 3-9 3s-9-1.34-9-3" />
              <path d="M3 5c0 1.66 4 3 9 3s9-1.34 9-3" />
            </svg>
            <h3>Data Analytics</h3>
            <p>Advanced analytics to identify patterns and optimize consumption</p>
          </div>

          <div className="feature-card">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5" />
            </svg>
            <h3>Smart Insights</h3>
            <p>AI-powered recommendations for energy efficiency</p>
          </div>
        </div>

        <div className="cta-section">
          <Link to="/waitinglist" className="cta-button">
            Join the Waitlist
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M5 12h14M12 5l7 7-7 7" />
            </svg>
          </Link>
        </div>
      </main>

      <footer className="landing-footer">
        <div className="footer-content">
          <div className="footer-section">
            
            <p> (c) 2024 kWIQly GmbH</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;