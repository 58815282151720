import { ModeledData } from "../../domain/ModeledData";
import { NumberedModelRow } from "../../domain/NumberedModelRow";

export interface EventSummary {
    event_no: number;
    event_started: string;
    event_to: string;
    open_duration: number;
    waste: number;
    expected: number;
    open_ended: boolean;
}

/**
 * Summary of Events by Event No
 * @param numbered_event_model The event history
 * @returns Array of event summaries with event details
 */
export function summarisedEvents(numbered_event_model: NumberedModelRow[]): EventSummary[] {
    const last_event_no = Math.max(...numbered_event_model
        .filter(row => row.event_no !== undefined && isFinite(row.event_no!))
        .map(row => row.event_no!));

    // Filter and group by event_no
    const eventGroups = new Map<number, NumberedModelRow[]>();
    numbered_event_model
        .filter(row => 
            row.event_no !== undefined && 
            isFinite(row.event_no!) && 
            row.cause === 'Clean'
        )
        .forEach(row => {
            if (!eventGroups.has(row.event_no!)) {
                eventGroups.set(row.event_no!, []);
            }
            eventGroups.get(row.event_no!)!.push(row);
        });

    // Create summaries for each event
    const summaries: EventSummary[] = [];
    eventGroups.forEach((rows, event_no) => {
        const event_started = new Date(Math.min(...rows.map(r => new Date(r.ts).getTime())));
        const event_to = new Date(Math.max(...rows.map(r => new Date(r.ts).getTime())));
        
        summaries.push({
            event_no,
            event_started: event_started.toISOString(),
            event_to: event_to.toISOString(),
            waste: rows.reduce((sum, row) => sum + (row.waste || 0), 0) / rows.length,
            expected: rows.reduce((sum, row) => sum + (row.pred || 0), 0) / rows.length,
            open_ended: event_no === last_event_no,
            open_duration: Math.floor((event_to.getTime() - event_started.getTime()) / (1000 * 60 * 60 * 24)) + 1
        });
    });

    return summaries.sort((a, b) => a.event_no - b.event_no);
}

/**
 * Create a Map of Event Summaries
 * @param numbered_event_model The event history
 * @returns A Map with event_no as key and EventSummary as value
 */
export function event_summaries(numbered_event_model: NumberedModelRow[]): Map<number, EventSummary> {
    const summaries = summarisedEvents(numbered_event_model);
    return new Map(summaries.map(summary => [summary.event_no, summary]));
}

/**
 * Summary Meta of Last Event
 * @param summary_of_events Array of event summaries
 * @returns The last event's summary or undefined if no events
 */
export function current_event_summary(summary_of_events: EventSummary[]): EventSummary | undefined {
    if (summary_of_events.length === 0) return undefined;
    return summary_of_events.reduce((latest, current) => 
        current.event_no > latest.event_no ? current : latest
    );
}
