import { PentileDataPoint, PentileDataPointCompact } from "../../domain/PentileDataPoint";
import detectHeatingOnPeriods, { detectHeatingOnPeriods2, identifyHeatingCycles, identifyHeatingTransitions } from "./ntmodes";


function calculateMode(maxPowerIdx: number, minPowerIdx: number): number[] {
    const arrayLength = 48;
    const mode: number[] = Array(arrayLength).fill(0); // Initialize with zeros
    console.log("maxPowerIdx: " + maxPowerIdx + " minPowerIdx: " + minPowerIdx)
    if (maxPowerIdx < minPowerIdx) {
        for (let i = 0; i < arrayLength; i++) {
            if (i < maxPowerIdx) {
                mode[i] = 0;
            } else if (i >= maxPowerIdx && i < minPowerIdx) {
                mode[i] = 1;
            } else {
                mode[i] = 0;
            }
        }
    } else if (maxPowerIdx > minPowerIdx) {
        for (let i = 0; i < arrayLength; i++) {
            if (i < minPowerIdx) {
                mode[i] = 1;
            } else if (i >= minPowerIdx && i < maxPowerIdx) {
                mode[i] = 0;
            } else {
                mode[i] = 1;
            }
        }
    }

    return mode;
}

// Example usage
// const result = calculateMode(10, 20);
// console.log(result);
export function splitModes(slope: number[], readings: number[]): number[] {
    // convert input to days, e.g. [0,1, 2, 3, 4, 5, 6, 7, 8, 9]=>[[0,1], [2, 3], [4, 5, 6], [7, 8, 9]]
    // input has a length of 7*48=336 elements
    // we want a 2D array with 7 rows and 48 columns
    const averageWeekDayLoad = readings.slice(0, 5 * 48).reduce((a, b) => a + b, 0) / 5;
    const days: number[][] = [[], [], [], [], [], [], []];
    for (let day = 0; day < 7; day++) {
        for (let hour = 0; hour < 48; hour++) {
            days[day].push(slope[day * 48 + hour]);
        }
    }
    
    // find min and max for each day
    const modes = days.map((day: number[], index) => {
        const dailySum = readings.slice(index * 48, (index + 1) * 48).reduce((a, b) => a + b, 0);
        // sum from idx=12 to idx=46 divided by daily sum
        const dayLoadPct = readings.slice(index * 48 + 12, (index + 1) * 48 - 12).reduce((a, b) => a + b, 0) / dailySum;
        const min = Math.min(...day);
        const max = Math.max(...day);
        const minIndex = day.indexOf(min) - 1;
        const maxIndex = day.indexOf(max) - 1;
        
        if (dayLoadPct < 0.48 || dayLoadPct > 0.52) {
            return calculateMode(maxIndex, minIndex);
        }
        return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    });
    return modes.flat();  // Flatten the array of arrays into a single array


}

export function slope(arr: number[]): number[] {
    
    return [arr[arr.length - 1] - arr[0]].concat(arr.slice(1).map((value, index) => value - arr[index]));
}

export function getModes(pentile: PentileDataPointCompact[]): PentileDataPointCompact[] {
    let result = pentile
    let modes:number[][] = [[], [], [], [], []]
    for (let pentileIndex = 0; pentileIndex < 5; pentileIndex++) {
       
        const readings = pentile.map(reading => reading.reading[pentileIndex]);
      
        const slopes = smooth(slope(smooth(readings)));

        modes[pentileIndex] = detectHeatingOnPeriods2(readings).map(mode => mode ? 1 : 0);
      
        // if mode=3 and slope>0 then mode=1 else mode=0
    }
   
        for (let point = 0; point <pentile.length; point++) {
            for (let pentileIndex = 0; pentileIndex < 5; pentileIndex++) {
            if(!result[point].mode){
                result[point].mode = [0,0,0,0,0]
            }
            if(result[point]&&result[point].mode){
            //@ts-ignore
            result[point].mode[pentileIndex] = modes[pentileIndex][point];
            }
        }
    }
    return result

}

export function getModes2(pentile: PentileDataPointCompact[]): PentileDataPointCompact[] {
    let result = pentile
    let modes:number[][] = [[], [], [], [], []]
    for (let pentileIndex = 0; pentileIndex < 5; pentileIndex++) {
       
        const readings = pentile.map(reading => reading.reading[pentileIndex]);
      
        const slopes = smooth(slope(smooth(readings)));

        modes[pentileIndex] = splitModes(slopes, readings);
      
        // if mode=3 and slope>0 then mode=1 else mode=0
    }
   
        for (let point = 0; point <pentile.length; point++) {
            for (let pentileIndex = 0; pentileIndex < 5; pentileIndex++) {
            if(!result[point].mode){
                result[point].mode = [0,0,0,0,0]
            }
            if(result[point]&&result[point].mode){
            //@ts-ignore
            result[point].mode[pentileIndex] = modes[pentileIndex][point];
            }
        }
    }
    return result

}

// smooth the readings of a pentile with a moving average
export function smooth(readings: number[]): number[] {
    const smoothedReadings = readings.map((value, index) => {
        const start = index === 0 ? 0 : index - 1;
        const end = index === readings.length - 1 ? readings.length - 1 : index + 1;
        return readings.slice(start, end + 1).reduce((a, b) => a + b, 0) / (end - start + 1);
    });
    return smoothedReadings;
}
