import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { fetchMeterDataSimple, processMeterDataSimple } from '../services/meterDataService';
import TimeChart from './charts/TimeChart';
import OATChart from './charts/OATChart';
import EventHistoryChart from './charts/EventHistoryChart';
import NtileEventProfileChart from './charts/NtileEventProfileChart';
import { useQuery } from '@tanstack/react-query';

const chartDimensions = { width: 800, height: 400 };

const SingleMeterLabeling: React.FC = () => {
  const { meterkey } = useParams<{ meterkey: string }>();

  // First query to fetch raw meter data
  const rawDataQuery = useQuery({
    queryKey: ['rawMeterData', meterkey],
    queryFn: () => fetchMeterDataSimple(meterkey || ''),
    enabled: !!meterkey
  });

  // Second query to process the data, dependent on the first query
  const processedDataQuery = useQuery({
    queryKey: ['processedMeterData', meterkey],
    queryFn: () => processMeterDataSimple(rawDataQuery.data!),
    enabled: !!rawDataQuery.data
  });

  if (rawDataQuery.isLoading) {
    return <div>Loading raw data...</div>;
  }

  if (rawDataQuery.error) {
    return <div>Error loading raw data: {(rawDataQuery.error as Error).message}</div>;
  }

  if (processedDataQuery.isLoading) {
    return <div>Processing data...</div>;
  }

  if (processedDataQuery.error) {
    return <div>Error processing data: {(processedDataQuery.error as Error).message}</div>;
  }

  const processedData = processedDataQuery.data;
  const rawData = rawDataQuery.data;

  if (!processedData || !rawData) {
    return <div>No data available</div>;
  }

  return (
    <div className="diagramm-container">
      <div className="diagramm card">
        <h4>Model & actual Power</h4>
        <TimeChart 
          excludedReadings={rawData.excludedReadings}
          cleanedReadings={rawData.cleanedReadings}
          data={processedData.modeledData} 
          width={chartDimensions.width} 
          height={chartDimensions.height}
          xDomain={[new Date('2023-01-01'), new Date('2024-12-31')]}
        />
      </div>

      <div className="diagramm card">
        <h4>Cumulative Deviation kWh</h4>
        <EventHistoryChart 
          eventList={processedData.eventSummary} 
          data={processedData.modelWithEvents} 
          width={chartDimensions.width} 
          height={chartDimensions.height}
          xDomain={[new Date('2023-01-01'), new Date('2024-12-31')]} 
        />
      </div>

      <div className="diagramm card">
        <h4>OAT Chart</h4>
        <OATChart 
          rawData={rawData.cleanedReadings} 
          data={processedData.modelWithEvents} 
          width={chartDimensions.width} 
          height={chartDimensions.height} 
        />
      </div>

      <div className="diagramm card">
        <h4>Event & Prior Year By Time of Week and Temperature</h4>
        <NtileEventProfileChart 
          pentiledModel={processedData.pentiledProfile} 
          pentiledEventModel={processedData.pentiledEventProfile} 
          width={chartDimensions.width} 
          height={chartDimensions.height}
        />
      </div>
    </div>
  );
};

export default SingleMeterLabeling;
