import React from 'react';
import { StatusBox } from './StatusBox';
import { EventFeatures } from '../domain/EventFeatures';
import { tooltip } from 'leaflet';

interface TimeClockStatusProps {
  features?: EventFeatures; 
}

export const TimeClockStatus: React.FC<TimeClockStatusProps> = ({ features }) => {
  if (!features  ) return null;
  const gini_threshold_for_time_clock_use = 0.05 // Increasing this value will reduce the likelihood of time-clocks determined as being in use

  // Anything less than 10% turn-down we consider negligible and not likely to be associated with time-clock use
  const turndown_threshold_for_time_clock_use = 0.1
  
  // 6 hours of the day of contiguous low periods we set as a minimum for time-clocks to be in use
  const low_prop_threshold_for_time_clock_use = 0.25
  
  // High retracement is a sign of timeclocks not in use
  const high_retrace_threshold_for_time_clock_use = 0.75
  const gini_change = features.event_gini - features.normal_gini
  const timeclock_extended_threshold = 1/24
  // How much activity extension has occurred ?
  const low_prop_change = features.event_avg - features.normal_avg - features.event_low_prop - features.normal_low_prop
  
  // one half hour as a contigous low change threshold
  const contigous_low_change_threshold = 1/48
  const contig_low_change = features.event_contig_low - features.normal_contig_low
  
  let color='gray'
  let tooltip='No Time Clocks in use'
  const time_clocks_in_use_normally = features.normal_gini > gini_threshold_for_time_clock_use &&
  features.normal_td > turndown_threshold_for_time_clock_use &&
  features.normal_low_prop > low_prop_threshold_for_time_clock_use &&
  features.normal_retracement < high_retrace_threshold_for_time_clock_use

  const time_clocks_in_use_during_event = 
  features.event_gini > gini_threshold_for_time_clock_use && 
  features.event_td > turndown_threshold_for_time_clock_use &&
  features.event_low_prop > low_prop_threshold_for_time_clock_use &&
  features.event_retracement < high_retrace_threshold_for_time_clock_use 

  const time_clocks_impact_reduced = 
  features.event_gini < features.normal_gini || 
  features.event_td < features.normal_td ||
  features.event_low_prop < features.normal_low_prop ||
  features.event_retracement > features.normal_retracement

  const timeclocks_probably_extended = (time_clocks_in_use_normally ) &&
  low_prop_change > timeclock_extended_threshold &&
  contig_low_change > contigous_low_change_threshold &&
  gini_change < 0
  const active_load_increase =(features.event_td > features.normal_td) && (features.event_avg > features.normal_avg) && (features.event_gini > features.normal_gini)

  if (time_clocks_in_use_normally && time_clocks_in_use_during_event && !time_clocks_impact_reduced && !timeclocks_probably_extended) {
    color = 'green'
    tooltip = 'Time Clocks in use'
  } else if (time_clocks_in_use_normally && !time_clocks_in_use_during_event ) {
    color = 'orange'
    tooltip = 'Timeclocks no longer in use'
  } else if (time_clocks_in_use_normally &&  time_clocks_impact_reduced) {
    color = 'orange'
    tooltip = 'Evidence for reduced impact of time-clocks (perhaps one of several overridden)'
  } else if (time_clocks_in_use_normally && timeclocks_probably_extended) {
    color = 'orange'
    tooltip = 'Time-clocks appear to be in use with active periods probably extended'
  }

  if (active_load_increase) {
    color = 'red'
    tooltip = 'Active load increased'
  }
  return (
    <StatusBox title="Time Clock" tooltip={tooltip} color={color} icon={<i className="pi pi-clock"></i>} />
  );
};

export default TimeClockStatus;
