import React, { useState } from 'react';
import { FileUpload } from 'primereact/fileupload';
import moment from 'moment';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import WeekProfileChart from "./charts/WeekProfileChart";
import {calculateWeekProfile, CombinedTimeSeries, detectDateFormat, formatData} from "../model/util";
import {TabPanel, TabView} from "primereact/tabview";
import {DateMetrics} from "./interfaces/interfaces";

import Papa from 'papaparse';
import findIdentifierColumns, { findAllDateColumns } from '../model/mapcsv';
import { Reading } from '../domain/Reading';
import { set } from 'date-fns';


interface DataUploadProps {
  onDataLoaded: (data: Reading[]) => void
}

interface TimeSeriesData {
  [key: string]: any;
}

interface IdentifierColumnInfo {
  columnIndex: number;
  isUniquePerDate: boolean;
}

interface DataRow {
  id1: string;
  id2: string;
  ts:Date
  value:number
  // Add more fields as needed
}

const DataUpload: React.FC<DataUploadProps> = ({ onDataLoaded }) => {
  const [previewData, setPreviewData] = useState<Reading[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [dateMetrics, setDateMetrics] = useState<DateMetrics | null>(null);
  const [hourlyAverages, setHourlyAverages] = useState<any>({});
  const handleFileSelect = (event: any) => {
    const file = event.files[0];
    if (file) {
      Papa.parse<string[][]>(file, {
        header: false,
        dynamicTyping: true,
        complete: (results) => {
          const data = results.data;
          const dateColumns = findAllDateColumns(data);
          const identifierColumns = findIdentifierColumns(data, dateColumns);
          //@ts-ignore
          const readingDays: Reading[] = data.map(row => {
            const values = row.slice(dateColumns[0].columnIndex + 1).flat().map((value:string) => parseFloat(value))
            
            return {
              ts: row[0],
              values: values,
              rate: values.reduce((total, value) => total + value, 0)/values.length // Default rate, adjust as needed
            };  
          });

         setPreviewData(readingDays);
          onDataLoaded(readingDays);
          //setPreviewData(readingDays);
          
        
        },
      });
    }
  };

  

  

   
  return (
    <div className={'dataupload'}>
      {previewData.length == 0 && (
      <FileUpload name="demo[]" url="./upload" customUpload uploadHandler={handleFileSelect}
                  accept=".csv" maxFileSize={10000000000} mode="basic" auto chooseLabel="Select CSV File" />
      )}
      {previewData.length > 0 && (
        <button onClick={() => {
          setPreviewData([])
          onDataLoaded([])
        }}>Clear</button>
      )}
    </div>

  );
};

export default DataUpload;
