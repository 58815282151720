import React from 'react';
import { StatusBox } from './StatusBox';
import { EventFeatures } from '../domain/EventFeatures';
import { tooltip } from 'leaflet';

interface BaseLoadStatusProps {
  features?: EventFeatures; 
}

export const BseLoadStatus: React.FC<BaseLoadStatusProps> = ({ features }) => {
  if (!features  ) return null;
  const baseload_increased = features.event_baseload > features.normal_baseload &&
  features.event_low > features.normal_low &&
  features.event_hi > features.normal_hi &&
  features.event_td < features.normal_td &&
  features.event_gini < features.normal_gini

  let color='gray'
  let tooltip='Base load unchanged'
  if (baseload_increased) {
    color='red'
    tooltip='Base load increased'
  }
  return (
    <StatusBox title="Base Load" tooltip={tooltip} color={color} icon={<i className="pi pi-clock"></i>} />
  );
};

export default BseLoadStatus;
