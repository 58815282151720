import React, { useRef, useEffect, useState } from 'react';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';
import { useQuery } from '@tanstack/react-query';

import EventHistoryChart  from './charts/EventHistoryChart';
import  OATChart  from './charts/OATChart';
import NtileEventProfileChart  from './charts/NtileEventProfileChart';
import { TimeClockStatus } from './TimeClockStatus';
import BaseLoadStatus from './BaseLoadStatus';
import { ModeledData } from '../domain/ModeledData';
import { RawDataItem } from '../domain/RawDataItem';
import { NumberedModelRow } from '../domain/NumberedModelRow';
import { PentileDataPoint, PentileDataPointCompact } from '../domain/PentileDataPoint';
import { EventFeatures } from '../domain/EventFeatures';
import { fetchAndProcessMeterData, fetchAndProcessMeterData365 } from '../services/meterDataService';
import TimeChart from './charts/TimeChart';
import { getModes, smooth } from '../model/modes/modes';

const SingleMeterKEMS: React.FC = () => {
  const { meterkey } = useParams<{ meterkey: string }>();
  const [show365Days, setShow365Days] = useState(true);
  const location = useLocation();
  const [chartDimensions, setChartDimensions] = useState({ width: 800, height: 400 });
  const [cleanedReadings, setCleanedReadings] = useState<RawDataItem[]>([]);
  const [excludedReadings, setExcludedReadings] = useState<RawDataItem[]>([]);
  const [eventFeatures, setEventFeatures] = useState<EventFeatures>();
  const [modelRows, setModelRows] = useState<NumberedModelRow[]>([]);
  const [modelWithEvents, setModelWithEvents] = useState<NumberedModelRow[]>([]);
  const [weekData, setWeekData] = useState<ModeledData[]>([]);
  const [eventSummary, setEventSummary] = useState<any[]>([]);
  const [pentiledProfile, setPentiledProfile] = useState<PentileDataPointCompact[]>([]);
  const [pentiledEventProfile, setPentiledEventProfile] = useState<PentileDataPoint[]>([]);

  const { data: meterData, isLoading, error } = useQuery({
    queryKey: ['meterData', meterkey, show365Days],
    queryFn: () => show365Days ? 
      fetchAndProcessMeterData365(meterkey || '') : 
      fetchAndProcessMeterData(meterkey || ''),
    enabled: !!meterkey,
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
    gcTime: 30 * 60 * 1000, // Keep in cache for 30 minutes
  });

  useEffect(() => {
    if (meterData) {
      setCleanedReadings(meterData.cleanedReadings);
      setExcludedReadings(meterData.excludedReadings);
      setWeekData(meterData.modeledData);
      setEventSummary(meterData.eventSummary);
      setPentiledProfile(getModes(meterData.pentiledProfile));
      setPentiledEventProfile(meterData.pentiledEventProfile);
      setEventFeatures(meterData.eventFeatures);
      setModelRows(meterData.modelWithEvents);
      setModelWithEvents(meterData.modelWithEvents);
    }
  }, [meterData]);

  useEffect(() => {
    const handleResize = () => {
      setChartDimensions({
        width: window.innerWidth * 0.95,
        height: window.innerHeight * 0.4,
      });
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderContent = () => {
    if (!meterkey) {
      return <div>No meter key provided</div>;
    }

    if (isLoading) {
      return <div>Loading...</div>;
    }

    if (error) {
      return (
        <div className="error-container">
          <Message severity="error" text={String(error)} />
        </div>
      );
    }

    return (
      <div style={{ position: 'relative', minHeight: '100vh' }}>
        {isLoading && (
          <div style={{ 
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: 1000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
              <ProgressSpinner style={{width: '50px', height: '50px'}} />
              <span className="mt-2">Thinking...</span>
            </div>
          </div>
        )}
        <div className="diagramm-container">
          <TimeClockStatus features={eventFeatures}/>
          <BaseLoadStatus features={eventFeatures}/>
          <div className="diagramm card">
            <h4>Model & actual Power</h4>
            <TimeChart 
              excludedReadings={excludedReadings}
              cleanedReadings={cleanedReadings}
              data={weekData} 
              width={chartDimensions.width} 
              height={chartDimensions.height}
              xDomain={cleanedReadings.length > 0 
                ? [new Date(cleanedReadings[0].ts), new Date(cleanedReadings[cleanedReadings.length-1].ts)]
                : [new Date(), new Date()]}
            />
          </div>

          <div className="diagramm card">
            <h4>Cumulative Deviation kWh</h4>
            <EventHistoryChart 
              eventList={eventSummary || []} 
              data={modelWithEvents} 
              width={chartDimensions.width} 
              height={chartDimensions.height}
              xDomain={[new Date(cleanedReadings[0]?.ts), new Date(cleanedReadings[cleanedReadings.length-1]?.ts)]}
            />
          </div>

          <div className="diagramm card">
            <h4>OAT Chart</h4>
            <OATChart 
              rawData={cleanedReadings} 
              data={modelWithEvents} 
              width={chartDimensions.width} 
              height={chartDimensions.height} 
            />
          </div>
        
          <div className="diagramm card">
            <h4>Event & Prior Year By Time of Week and Temperature</h4>
            <NtileEventProfileChart 
              pentiledModel={pentiledProfile||[]} 
              pentiledEventModel={pentiledEventProfile||[]} 
              width={chartDimensions.width} 
              height={chartDimensions.height} 
            />
          </div>
         
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div style={{ marginBottom: '1rem' }}>
        <button 
          onClick={() => setShow365Days(!show365Days)}
          style={{
            padding: '8px 16px',
            backgroundColor: '#007bff',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          {show365Days ? 'Show All Data' : 'Show Last 365 Days'}
        </button>
      </div>
      {renderContent()}
    </React.Fragment>
  );
};

export default SingleMeterKEMS;
