import React from 'react';
import { Text } from '@visx/text';

interface LegendProps {
  days: string[];
  colors: string[];
  visibleDays: Set<number>;
  toggleDay: (day: number) => void;
  showExcluded: boolean;
  toggleExcluded: () => void;
  width: number;
}

const Legend: React.FC<LegendProps> = ({ days, colors, visibleDays, toggleDay, showExcluded, toggleExcluded, width }) => {
  const legendWidth = width* 0.9 ; // Adjust this value as needed
  const itemWidth = legendWidth / 8;

  return (
    <g>
      {days.map((day, index) => (
        <g 
          key={day.slice(0, 3)} 
          transform={`translate(${index * itemWidth}, 0)`}
          style={{ cursor: 'pointer' }}
          onClick={() => toggleDay(index + 1)}
        >
          <circle
            r={4}
            fill={colors[index % colors.length]}
            opacity={visibleDays.has(index + 1) ? 1 : 0.3}
          />
          <Text
            x={5}
            y={5}
            fontSize={10}
            textAnchor="start"
            opacity={visibleDays.has(index + 1) ? 1 : 0.3}
          >
            {day.slice(0, 3)} 
          </Text>
        </g>
      ))}
      <g 
        style={{ cursor: 'pointer' }}
        onClick={toggleExcluded}
        opacity={showExcluded ? 1 : 0.3}
      >
         
        <Text x={legendWidth +10} y={5} fontSize={10} textAnchor="end">X Excluded</Text>
      </g>
    </g>
  );
};

export default Legend;