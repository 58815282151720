import axios from 'axios';
import { RawDataItem } from '../domain/RawDataItem';
import { ModeledData } from '../domain/ModeledData';
import { KServerFittedData } from '../domain/KServerFittedData';
import { numberEvents } from '../model/kintelligence/eventNumbering';
import { summarisedEvents } from '../model/kintelligence/eventSummary';
import { assignPentile, pentileProfiler, pentileDataPointCompact } from '../model/kfeature/pentileProfile';
import { EventFeatures } from '../domain/EventFeatures';
import { KServerResponse } from '../domain/KServerResponse';
import { PentileDataPoint, PentileDataPointCompact } from '../domain/PentileDataPoint';
import { NumberedModelRow } from '../domain/NumberedModelRow';

export interface ProcessedMeterData {
  cleanedReadings: RawDataItem[];
  excludedReadings: RawDataItem[];
  modeledData: ModeledData[];
  modelWithEvents: NumberedModelRow[];
  eventSummary: EventSummary[];
  pentiledProfile: PentileDataPointCompact[];
  pentiledEventProfile: PentileDataPoint[];
  eventFeatures?: EventFeatures;
}

interface DayProcessor {
  day: number;
  data: ModeledData[];
}

const modelDayData = async (rawData: RawDataItem[], day: number): Promise<ModeledData[]> => {
  if(!rawData.filter) { return []; }
  const dayData = rawData.filter(d => d.cause === 'Clean' && d.iso_dow === day);
  const response = await axios.post<KServerFittedData>(`${process.env.REACT_APP_API_URL}/cobs_fit`, {
    x: dayData.map(d => d.oat),
    y: dayData.map(d => d.rate)
  });

  return dayData.map((d, index) => ({
    ...d,
    pred: response.data.fitted[index],
    used: true,
    waste: d.rate - response.data.fitted[index]
  }));
};

const fetchEventFeaturesx = async (meterkey: string): Promise<EventFeatures | undefined> => {
  try {
    const response = await axios.get<KServerResponse>(`${process.env.REACT_APP_API_URL}/meter_data`, {
      params: { meter_key: meterkey }
    });

    return response.data.event_features?response.data.event_features[0]:{}as EventFeatures;
  } catch (error) {
    console.error('Error fetching event features:', error);
    return undefined;
  }
};

export interface RawMeterData {
  cleanedReadings: RawDataItem[];
  excludedReadings: RawDataItem[];
  eventFeatures?: EventFeatures;
}

export const fetchMeterData = async (meterkey: string): Promise<RawMeterData> => {
  // Fetch both cleaned readings and event features in parallel
  const [cleanedResponse] = await Promise.all([
    axios.get(`${process.env.REACT_APP_API_URL}/cleaned_readings`, {
      params: { meter_key: meterkey }
    })
  ]);

  const rawData: RawDataItem[] = cleanedResponse.data;
  const cleanedReadings = rawData;
  const excludedReadings = rawData.filter?rawData.filter(d => d.cause !== 'Clean'):[];

  return {
    cleanedReadings,
    excludedReadings
  };
};



export const fetchMeterDataSimple = async (meterkey: string): Promise<RawMeterData> => {
  // Fetch both cleaned readings and event features in parallel
  const [cleanedResponse] = await Promise.all([
    axios.get(`${process.env.REACT_APP_API_URL}/cleaned_readings`, {
      params: { meter_key: meterkey }
    }),
    
  ]);

  const rawData: RawDataItem[] = cleanedResponse.data;
  const cleanedReadings = rawData;
  const excludedReadings = rawData.filter?rawData.filter(d => d.cause !== 'Clean'):[];

  return {
    cleanedReadings,
    excludedReadings
    
  };
};

export const processMeterData = async (rawData: RawDataItem[]): Promise<ProcessedMeterData> => {
  if(rawData.length < 300) {
    return {
      cleanedReadings: rawData,
      excludedReadings: [],
      modeledData: [],
      modelWithEvents: [],
      eventSummary: [],
      pentiledProfile: [],
      pentiledEventProfile: [],
      eventFeatures: undefined
    };
  } 
  const cleanedReadings = rawData;
  const excludedReadings = rawData.filter?rawData.filter(d => d.cause !== 'Clean'):[];

  // Process each day in parallel
  const daysToProcess: number[] = [1, 2, 3, 4, 5, 6, 7];
  const processedDays = await Promise.all(
    daysToProcess.map(day => modelDayData(rawData, day))
  );

  // Create modeledData object
  const modeledData: ModeledData[] = processedDays.flat();


  // Process events
  const flatModeledData = processedDays.flat();
  const modelWithEvents = numberEvents(flatModeledData);
  const eventSummary = summarisedEvents(modelWithEvents);
  const latestEventNo = eventSummary.reduce((latest, current) => 
    current.event_no > latest.event_no ? current : latest
  ).event_no;

  // Process pentile data
  const pentiledModel = assignPentile(modelWithEvents.filter(d => d.event_no !== latestEventNo));
  const pentiledModelEvents = assignPentile(modelWithEvents.filter(d => d.event_no === latestEventNo));
  
  const pentiledProfile = pentileProfiler(pentiledModel, 48);
  const pentiledProfileEvents = pentileProfiler(pentiledModelEvents, 48);

  return {
    cleanedReadings,
    excludedReadings,
    modeledData,
    modelWithEvents,
    eventSummary,
    pentiledProfile: pentileDataPointCompact(pentiledProfile),
    pentiledEventProfile: pentiledProfileEvents,
    eventFeatures: undefined // This will need to be passed in if needed
  };
};

export const fetchAndProcessMeterData = async (meterkey: string): Promise<ProcessedMeterData> => {
  const rawData = await fetchMeterData(meterkey);
  const processedData = await processMeterData(rawData.cleanedReadings);
  return {
    ...processedData,
    eventFeatures: rawData.eventFeatures
  };
};

export const fetchAndProcessMeterData365 = async (meterkey: string): Promise<ProcessedMeterData> => {
  const rawData = await fetchMeterData(meterkey);
  // only process the latest 365 days
  const processedData = await processMeterData(rawData.cleanedReadings.filter(d => d.ts > new Date(Date.now() - 365 * 24 * 60 * 60 * 1000).toISOString()));
  return {
    ...processedData,
    eventFeatures: rawData.eventFeatures
  };
};



export const processMeterDataSimple = async (rawData:RawMeterData): Promise<ProcessedMeterData> => {
  
  const processedData = await processMeterData(rawData.cleanedReadings);
  return processedData 
};