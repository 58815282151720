import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import '../KPIBox.css'; // We'll create this file for the styles

export interface StatusData {
  title: string;
  tooltip: string;
  color: string;
  icon?: React.ReactNode; // Add an optional icon prop
}

export const StatusBox: React.FC<StatusData> = ({ title, tooltip, color, icon }) => (
  <div className="kpi-box" style={{ borderColor: color }} data-pr-tooltip={tooltip}>
    <div className="kpi-content">
      {icon && <div className="kpi-icon" style={{ color }}>{icon}</div>}
      <div className="kpi-info">
       
        <div className="kpi-value" style={{ color }}>
          {title}
        </div>
      </div>
    </div>
    <Tooltip target=".kpi-box" />
  </div>
);