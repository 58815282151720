import { kmeans } from 'ml-kmeans';

export function identifyHeatingCycles(powerReadings: number[]): boolean[] {
    const HALF_HOURS_PER_DAY = 48;
    const MORNING_START = 6 * 2; // 6:00 AM in half-hour intervals
    const EVENING_END = 16 * 2;  // 4:00 PM in half-hour intervals
    const MAX_CYCLES = 7;

    // Helper function to check if the heating is off (base load or zero)
    const isHeatingOff = (reading: number): boolean => {
        return reading <= 0 || reading < 0.1 * Math.max(...powerReadings); // base load check
    };

    // Function to find 7 local maxima with significant preceding increase
    function findMaximaWithSpikes() {
        const maxima = [];
        for (let i = 1; i < powerReadings.length; i++) {
            const prev = powerReadings[(i - 1 + powerReadings.length) % powerReadings.length];
            const current = powerReadings[i];
            const next = powerReadings[(i + 1) % powerReadings.length];
            
            // Check if current is a local maximum with a significant increase from previous
            if (current > prev && current > next && current - prev > 0.2 * Math.max(...powerReadings)) {
                maxima.push({ index: i, value: current });
            }
        }

        // Sort by value to get the highest 7 maxima
        maxima.sort((a, b) => b.value - a.value);
        return maxima.slice(0, MAX_CYCLES).map(item => item.index);
    }

    const topMaxima = findMaximaWithSpikes();

    const result: boolean[] = new Array(powerReadings.length).fill(false);
    let heatingOn = false;
    let cycleCount = 0;

    for (let i = 0; i < powerReadings.length && cycleCount < MAX_CYCLES; i++) {
        if (!heatingOn) {
            // Check if current index is one of the top maxima or heating might be on without a spike
            if (topMaxima.includes(i) || (i >= MORNING_START && i < EVENING_END && !isHeatingOff(powerReadings[i]))) {
                if (cycleCount < MAX_CYCLES) {
                    heatingOn = true;
                    result[i] = true;
                    cycleCount++;
                }
            }
        } else {
            // Check for end of heating cycle
            if (isHeatingOff(powerReadings[i])) {
                heatingOn = false;
                // Check if heating continues into next day without a spike
                const nextIndex = (i + 1) % powerReadings.length;
                if (!topMaxima.includes(nextIndex) && cycleCount < MAX_CYCLES && !isHeatingOff(powerReadings[nextIndex])) {
                    result[nextIndex] = true;
                    heatingOn = true; // Extend heating into next day
                    cycleCount++;
                    i++; // Skip to next iteration since we've already handled this index
                }
            } else {
                result[i] = true; // Continue marking as heating is on
            }
        }
    }

    // If we hit the max cycle count, ensure no more cycles are started
    if (cycleCount >= MAX_CYCLES) {
        for (let i = 0; i < powerReadings.length; i++) {
            if (!result[i]) {
                result[i] = false; // Ensure no new cycles start
            }
        }
    }

    return result;
}

function detectHeatingOnPeriods(powerProfile: number[]): boolean[] {
    if (powerProfile.length !== 7 * 48) {
        throw new Error("Power profile must have 7 * 48 readings (one week).");
    }

    const dailyOnStatus: boolean[] = new Array(7).fill(false);

    for (let day = 0; day < 7; day++) {
        const startIndex = day * 48;
        const dayReadings = powerProfile.slice(startIndex, startIndex + 48);

        dailyOnStatus[day] = detectDailyHeating(dayReadings, day);
    }

    const weeklyStatus = [];

    for (let i = 0; i < 7; i++) {
      for(let j=0; j<48; j++){
          weeklyStatus.push(dailyOnStatus[i])
      }
    }

    return weeklyStatus;

}


function detectDailyHeating(dayReadings: number[], day: number): boolean {

    const earlyMorningStartIndex = 6 * 2; // Index for 6:00 AM (6 hours * 2 readings per hour)
    const morningEndIndex = 10 * 2;
    const plateauStartIndex = 11 * 2; // start of plateau at 11:00
    const plateauEndIndex = 17 * 2;   // end of plateau at 17:00


    let spikeDetected = false;
    let plateauDetected = false;
    let endOfHeatingReached = false;

    // Check for a morning spike (relative increase)
    for (let i = earlyMorningStartIndex + 1; i < morningEndIndex; i++) {
      if (dayReadings[i] > dayReadings[i - 1] * 1.3) { // Adjust the 1.3 as a sensitivity to detect the spike
         spikeDetected = true;
          break;
      }
    }


    // Check for a plateau after the spike
    if (spikeDetected) {
        let plateauSum = 0;
        let plateauCount = 0;
        for(let i = plateauStartIndex; i < plateauEndIndex; i++){
          plateauSum += dayReadings[i];
          plateauCount++;
        }
        
        if (plateauCount > 0){
           const averagePlateauPower = plateauSum / plateauCount;

           for(let i = plateauEndIndex; i < dayReadings.length; i++) {
            if(dayReadings[i] < averagePlateauPower * 0.6){
              endOfHeatingReached = true;
            }
            
          }

          if(plateauCount > 0){
              plateauDetected = true;
          }
        }


    } else{
      //No spike, so the previous day had a heating period
      //This method is not fool proof
      return day > 0
    }

    // Handle weekend case
    if((day === 5 || day === 6) && !spikeDetected){
        return false;
    }
    

    return spikeDetected && plateauDetected && endOfHeatingReached;

}

export default detectHeatingOnPeriods;

export function identifyHeatingTransitions(power: number[]): boolean[] {
    const result: boolean[] = new Array(power.length).fill(false);

    for (let i = 0; i < power.length; i++) {
        const prevIndex = i === 0 ? power.length - 1 : i - 1;
        const nextIndex = i === power.length - 1 ? 0 : i + 1;

        const current = power[i];
        const prev = power[prevIndex];
        const next = power[nextIndex];

        // Check for start transition (OFF -> ON)
        if (prev === 0 && current > 0) {
            result[i] = true;
        } else if (prev !== 0 && current / prev > 2) {
            result[i] = true;
        }

        // Check for end transition (ON -> OFF)
        if (next === 0 && current > 0) {
            result[i] = true;
        } else if (next !== 0 && current / next > 2) {
            result[i] = true;
        }
    }

    return result;
}


export function detectHeatingOnPeriods2(powerProfile: number[]): number[] {
    // Normalize data to [0, 1] range
    const minPower = Math.min(...powerProfile);
    const maxPower = Math.max(...powerProfile);
    const normalizedProfile = powerProfile.map(power => (power - minPower) / (maxPower - minPower));

    // Perform K-means clustering with 3 clusters
    const result = kmeans(normalizedProfile.map(p => [p]), 3, { 
        // Use k-means++ for better initial centroid placement
        initialization: 'kmeans++'
    });

    // Map the cluster indices to meaningful labels:
    // Since we don't know which cluster corresponds to what, we'll label based on mean power:
    let clusterMeans = result.centroids.map(c => c[0]); // Assuming centroids are single-dimensional
    let sortedClusterMeans = [...clusterMeans].sort((a, b) => a - b);

    // Map clusters to heating states
    const lowIndex = clusterMeans.indexOf(sortedClusterMeans[0]);
    const intermediateIndex = clusterMeans.indexOf(sortedClusterMeans[1]);
    const highIndex = clusterMeans.indexOf(sortedClusterMeans[2]);

    // Map cluster assignments to heating states
    const heatingState = result.clusters.map(cluster => {
        if (cluster === highIndex) return 2; // High power (heating on)
        if (cluster === lowIndex) return 0;  // Low power (heating off)
        return 1;                            // Intermediate power (heating transitioning or maintaining)
    });

    // Detect heating on periods based on patterns
    let resultState = new Array(heatingState.length).fill(0);
    let inHeatingCycle = false;
    let lastState = heatingState[0];

    for (let i = 0; i < heatingState.length; i++) {
        const currentState = heatingState[i];
        const prevState = heatingState[(i + heatingState.length - 1) % heatingState.length]; // Circular reference

        if (currentState === 2) { // High power
            inHeatingCycle = true;
            resultState[i] = 1;
        } else if (currentState === 1) { // Intermediate
            // Check for transition patterns
            if (lastState === 2 && prevState === 2) {
                resultState[i] = 1; // Still in heating phase if coming from high
            } else if (lastState === 0 && (prevState === 0 || prevState === 1)) {
                resultState[i] = 0; // Not in heating if coming from low or staying in intermediate
            } // Else, maintain last known state
        } else { // Low power
            if (!inHeatingCycle) {
                resultState[i] = 0;
            } else {
                // Check if we're transitioning out of a heating cycle
                if (i === 0 || heatingState[(i + heatingState.length - 1) % heatingState.length] === 1) {
                    resultState[i] = 1; // still heating if previous was intermediate
                } else {
                    inHeatingCycle = false;
                    resultState[i] = 0;
                }
            }
        }
        lastState = currentState;
    }

    return resultState;
}